import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule } from "@ngxs/store";
import { environment } from "../environments/environment";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { WaitlistState } from "./join-waitlist-dialog/waitlist.state";
import { ContactUsState } from "./home/contact-us/contact-us.state";
import { importProvidersFrom } from "@angular/core";
import { StayInLoopState } from "./footer/stay-in-loop/stay-in-loop.state";

const states = [WaitlistState, ContactUsState, StayInLoopState];

export const provideNgxs = () =>
  importProvidersFrom(
    NgxsModule.forRoot(states),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !environment.enableNgxsDebugging,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: !environment.enableNgxsDebugging,
    }),
  );
