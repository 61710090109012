import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { ApiService } from "../api/services";
import { JoinWaitlist } from "../api/models";

export class JoinWaitlistAction {
  static readonly type = "[Marketing] Join Waitlist";
  constructor(public request: JoinWaitlist) {}
}

@State<object>({
  name: "Waitlist",
  defaults: {},
})
@Injectable()
export class WaitlistState {
  constructor(private api: ApiService) {}

  @Action(JoinWaitlistAction)
  subscribeToWaitlist(_ctx: StateContext<object>, action: JoinWaitlistAction) {
    this.api
      .joinWaitlistPost({
        body: {
          firstName: action.request.firstName,
          lastName: action.request.lastName,
          email: action.request.email,
          phoneNumber: action.request.phoneNumber,
          profession: action.request.profession!,
        },
      })
      .subscribe();
  }
}
