<app-header
  headerColor="white"
  [isSticky]="true"
  (joinWaitlistClick)="joinWaitlist()"
></app-header>
<app-join-waitlist [(visible)]="showJoinWaitlistDialog"></app-join-waitlist>
<router-outlet (activate)="joinWaitlistSub($event)"></router-outlet>
@defer {
  <app-footer></app-footer>
}
