<p-dialog
  [(visible)]="visible"
  [modal]="true"
  styleClass="w-[21rem] sm:w-[38rem] md:w-[46rem]"
  (onHide)="onHide()"
>
  <ng-template pTemplate="header">
    @if (!isFormSubmitted) {
      <h4 class="text-darknavy">Request Demo</h4>
    } @else {
      <div class="flex flex-col gap-4 sm:w-[29.5rem]">
        <h4 class="text-darknavy w-8/12 sm:w-full">
          Thank you for requesting the demo!
        </h4>
        <div class="text-grey-6 body-1-regular w-5/6 sm:w-full">
          We’ll be in touch soon.
        </div>
      </div>
    }
  </ng-template>
  @if (!isFormSubmitted) {
    <form
      id="join-waitlist-form"
      class="flex flex-col relative items-start gap-6 sm:gap-8"
      [formGroup]="formGroup"
      (ngSubmit)="submit()"
    >
      <div class="flex flex-col sm:items-start gap-3 sm:gap-[1.3rem] w-full">
        <div
          class="flex flex-col sm:flex-row items-center sm:items-start gap-5 w-full"
        >
          <div class="flex flex-col gap-2 w-full sm:w-1/2 h-[4.5rem]">
            <label
              class="text-darknavy body-2-semibold"
              for="waitlistFirstName"
            >
              <span>First name </span>
              <span class="text-red-7">*</span>
            </label>
            <div class="flex flex-col gap-xs">
              <input
                type="text"
                pInputText
                id="waitlistFirstName"
                placeholder="What is your first name?"
                formControlName="firstName"
                maxlength="50"
              />
              @if (
                formGroup.controls["firstName"].hasError("required") &&
                formGroup.controls["firstName"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Please enter your first name.
                </small>
              } @else if (
                formGroup.controls["firstName"].hasError("pattern") &&
                formGroup.controls["firstName"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Only alphabetic and common special characters are allowed.
                </small>
              } @else if (
                formGroup.controls["firstName"].hasError("minlength") &&
                formGroup.controls["firstName"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  The input should be at least 2 characters long.
                </small>
              }
            </div>
          </div>
          <div class="flex flex-col gap-2 w-full sm:w-1/2 h-[4.5rem]">
            <label class="text-darknavy body-2-semibold" for="waitlistLastName">
              <span>Last name </span>
              <span class="text-red-7">*</span>
            </label>
            <div class="flex flex-col gap-xs">
              <input
                pInputText
                id="waitlistLastName"
                placeholder="What is your last name?"
                formControlName="lastName"
                maxlength="50"
              />
              @if (
                formGroup.controls["lastName"].hasError("required") &&
                formGroup.controls["lastName"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Please enter your last name.
                </small>
              } @else if (
                formGroup.controls["lastName"].hasError("pattern") &&
                formGroup.controls["lastName"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Only alphabetic and common special characters are allowed.
                </small>
              } @else if (
                formGroup.controls["lastName"].hasError("minlength") &&
                formGroup.controls["lastName"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  The input should be at least 2 characters long.
                </small>
              }
            </div>
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-center sm:items-start gap-5 w-full"
        >
          <div class="flex flex-col gap-2 w-full sm:w-1/2 h-[4.5rem]">
            <label class="text-darknavy body-2-semibold" for="waitlistEmail">
              <span>Email </span>
              <span class="text-red-7">*</span>
            </label>
            <div class="flex flex-col gap-xs">
              <input
                pInputText
                id="waitlistEmail"
                placeholder="your-email@example.com"
                formControlName="email"
              />
              @if (
                formGroup.controls["email"].hasError("required") &&
                formGroup.controls["email"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Please enter your email.
                </small>
              } @else if (
                formGroup.controls["email"].hasError("email") &&
                formGroup.controls["email"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Please enter a valid email address.
                </small>
              }
            </div>
          </div>
          <div class="flex flex-col gap-2 w-full sm:w-1/2 h-[4.5rem]">
            <label
              class="text-darknavy body-2-semibold"
              for="waitlistPhoneNumber"
            >
              Phone number
            </label>
            <div class="flex flex-col gap-xs">
              <p-inputMask
                id="waitlistPhoneNumber"
                mask="+1 (999) - 999 - 9999"
                placeholder="+1 (xxx) - xxx - xxxx"
                formControlName="phoneNumber"
                styleClass="w-full"
                autoClear="false"
              ></p-inputMask>
              @if (
                formGroup.controls["phoneNumber"].hasError("pattern") &&
                formGroup.controls["phoneNumber"].touched
              ) {
                <small class="text-red-7 leading-3 text-xs">
                  Please enter a valid US phone number.
                </small>
              }
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start gap-4">
          <div class="body-2-semibold text-darknavy">
            <span>What best describes you? </span>
            <span class="text-red-7">*</span>
          </div>
          <div
            class="grid grid-flow-row gap-y-2 sm:gap-x-4 sm:gap-y-4 sm:grid-cols-[11.75rem_14rem_1fr] md:gap-x-6"
          >
            <div
              *ngFor="let profession of professions"
              class="flex items-center"
            >
              <p-radioButton
                [value]="profession[0]"
                [label]="profession[1]"
                formControlName="selectedProfession"
                labelStyleClass="md:min-w-40"
              ></p-radioButton>
            </div>
          </div>
        </div>
      </div>
      <div class="flex just items-center sm:items-start">
        <p-button
          id="join-waitlist-submit-button"
          label="Send"
          styleClass="p-button-primary-navy w-[18.375rem] sm:w-40 h-10 button-semibold"
          [disabled]="formGroup.invalid"
          type="submit"
        ></p-button>
      </div>
    </form>
  } @else {
    <div class="overflow-hidden block h-[7.5rem] relative">
      <div class="absolute w-[20rem] h-[11.5rem] right-[-1.5rem]">
        <div
          class="bg-join-waitlist-dialog-image bg-no-repeat bg-contain bg-[center_-1.75rem] w-full h-full"
        ></div>
      </div>
    </div>
  }
</p-dialog>
