import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from "@angular/core";
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from "@angular/router";

import { routes } from "./app.routes";
import { provideNgxs } from "./ngxs.config";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { environment } from "../environments/environment";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { initializeGoogleAnalytics } from "./shared/utilities/analytics-initializer";
import { ApiModule } from "./api/api.module";
import { initializeHubSpot } from "@shared/utilities/hubspot-initializer";
import { HubSpotService } from "@app/services/hubspot.service";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: "top",
  anchorScrolling: "enabled",
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    importProvidersFrom(ApiModule.forRoot({ rootUrl: environment.apiUrl })),
    provideNgxs(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([BrowserAnimationsModule]),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGoogleAnalytics,
      deps: [GoogleAnalyticsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeHubSpot,
      deps: [HubSpotService],
      multi: true,
    },
  ],
};
