import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { RadioButtonModule } from "primeng/radiobutton";
import { InputMaskModule } from "primeng/inputmask";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { DialogModule } from "primeng/dialog";
import { Store } from "@ngxs/store";
import { JoinWaitlistAction } from "./waitlist.state";
import { ProfessionRadioButtonRecord } from "../shared/models/profession-type";
import { CustomValidators } from "../shared/utilities/custom-validator";
import { ProfessionType } from "../api/models";

@Component({
  selector: "app-join-waitlist",
  standalone: true,
  templateUrl: "./join-waitlist-dialog.component.html",
  imports: [
    CommonModule,
    InputTextModule,
    ButtonModule,
    RadioButtonModule,
    InputMaskModule,
    ReactiveFormsModule,
    DialogModule,
  ],
})
export class JoinWaitlistDialogComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  namePattern: string = "[a-zA-Z\\s\\-']+$";
  phonePattern: RegExp = /^\+1 \(\d{3}\) - \d{3} - \d{4}$/;
  isFormSubmitted: boolean = false;

  formGroup: FormGroup = new FormGroup({
    firstName: new FormControl("", [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(50),
      Validators.pattern(this.namePattern),
    ]),
    lastName: new FormControl("", [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(50),
      Validators.pattern(this.namePattern),
    ]),
    email: new FormControl("", [Validators.required, CustomValidators.email]),
    phoneNumber: new FormControl(null, Validators.pattern(this.phonePattern)),
    selectedProfession: new FormControl(null, [Validators.required]),
  });

  professions: [ProfessionType, string][] = Object.entries(
    ProfessionRadioButtonRecord,
  ).map(([key, value]) => [key as ProfessionType, value]);

  constructor(private store: Store) {}

  submit() {
    this.store
      .dispatch(
        new JoinWaitlistAction({
          firstName: this.formGroup.get("firstName")?.value.trim(),
          lastName: this.formGroup.get("lastName")?.value.trim(),
          email: this.formGroup.get("email")?.value.trim(),
          phoneNumber: this.formGroup.get("phoneNumber")?.value?.trim() || null,
          profession: this.formGroup.get("selectedProfession")?.value,
        }),
      )
      .subscribe(() => {
        this.isFormSubmitted = true;
        this.formGroup.reset();
      });
  }

  onHide() {
    this.visibleChange.emit(false);
    this.isFormSubmitted = false;
    this.formGroup.reset();
  }
}
