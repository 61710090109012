import { HubSpotService } from "@app/services/hubspot.service";
import { environment } from "@env/environment";

export function initializeHubSpot(hubSpotService: HubSpotService) {
  return () => {
    if (environment.enableHubSpot) {
      hubSpotService.init();
    }
  };
}
