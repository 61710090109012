import { Component, OnDestroy } from "@angular/core";
import { HeaderComponent } from "./header/header.component";
import { DialogModule } from "primeng/dialog";
import { JoinWaitlistDialogComponent } from "./join-waitlist-dialog/join-waitlist-dialog.component";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home/home.component";
import { RouterOutlet } from "@angular/router";
import { Subscription } from "rxjs";
import { FooterComponent } from "./footer/footer.component";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "./app.component.html",
  imports: [
    CommonModule,
    HeaderComponent,
    DialogModule,
    JoinWaitlistDialogComponent,
    FooterComponent,
    RouterOutlet,
  ],
})
export class AppComponent implements OnDestroy {
  showJoinWaitlistDialog: boolean = false;
  private subscriptions = new Subscription();

  joinWaitlist(): void {
    this.showJoinWaitlistDialog = true;
  }

  joinWaitlistSub(componentRef: HomeComponent): void {
    this.subscriptions.add(
      componentRef.joinWaitlistEmit?.subscribe(
        (res) => (this.showJoinWaitlistDialog = res),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
