import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class HubSpotService {
  constructor() {}

  init(): void {
    this.insertHubSpotScript();
  }

  private insertHubSpotScript(): void {
    const hsScript = document.createElement("script");
    hsScript.type = "text/javascript";
    hsScript.id = "hs-script-loader";
    hsScript.async = true;
    hsScript.defer = true;
    hsScript.src = `//js-na2.hs-scripts.com/${environment.hubSpotId}.js`;
    document.head.appendChild(hsScript);
  }
}
