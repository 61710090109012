import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../../environments/environment";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let dataLayer: any;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor(private router: Router) {}

  init(): void {
    this.onRouteChange();
    this.insertGTagScript();
  }

  private insertGTagScript(): void {
    const url = "https://www.googletagmanager.com/gtm.js?id=";
    const gTagScript = document.createElement("script");
    gTagScript.async = true;
    gTagScript.src = `${url}${environment.googleAnalyticsId}`;
    document.head.appendChild(gTagScript);

    const dataLayerScript = document.createElement("script");
    dataLayerScript.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.googleAnalyticsId}');
  `;
    document.head.appendChild(dataLayerScript);
  }

  private onRouteChange(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        dataLayer.push({
          event: "page_view",
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
