import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    loadComponent: () =>
      import("./home/home.component").then((mod) => mod.HomeComponent),
  },
  {
    path: "privacy-policy",
    loadComponent: () =>
      import("./privacy-policy/privacy-policy.component").then(
        (mod) => mod.PrivacyPolicyComponent,
      ),
    pathMatch: "full",
  },
  {
    path: "terms-of-service",
    loadComponent: () =>
      import("./terms-of-service/terms-of-service.component").then(
        (mod) => mod.TermsOfServiceComponent,
      ),
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "",
  },
];
