import { GoogleAnalyticsService } from "@app/services/google-analytics.service";
import { environment } from "@env/environment";

export function initializeGoogleAnalytics(
  googleAnalyticsService: GoogleAnalyticsService,
) {
  return () => {
    if (environment.enableAnalytics) {
      googleAnalyticsService.init();
    }
  };
}
